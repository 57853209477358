import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Typography, Grid, Paper } from "@mui/material";

import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";

import { Button, TextField } from "@mui/material";

//Query
import { useMutation, useQuery } from "@apollo/client";

import { graphql } from "../../gql";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: { height: "100%" },
    buttons: { padding: theme.spacing(2) },
    page: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(2),
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
  });
});

export const VoucherVolumeMutation = graphql(/* GraphQL */ `
  mutation voucherVolume($input: VoucherVolumeMutationInput!) {
    voucherVolume(input: $input) {
      success
      errors
    }
  }
`);

export const CheckVoucherQuery = graphql(/* GraphQL */ `
  query checkVoucher($code: String!) {
    checkVoucher(code: $code) {
      success
      type
      errors
      quota
    }
  }
`);

interface initialValues {
  volume: string;
  voucherId: string;
}

const Success = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CheckCircleIcon
        className={classes.icon}
        color="primary"
        fontSize="medium"
      />
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {t("Thank you, You have successfully redeemed your voucher.")}
        </Typography>
      </Grid>

      <Grid
        item
        justifyContent="center"
        alignItems="center"
        container
        spacing={2}
      >
        <Grid item>
          <Link
            to={"/volume_control"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="outlined" color="primary">
              {t("Check volume")}
            </Button>
          </Link>
        </Grid>

        <Grid item>
          <Link
            to={"/storefront/products"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="contained" color="primary">
              {t("Order now")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const RedeemPage = (props: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isSubmitted, setSubmitted] = React.useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [quota, setQuota] = React.useState("");

  const [voucherVolume, { error }] = useMutation(VoucherVolumeMutation);

  if (error) throw error;

  const { data } = useQuery(CheckVoucherQuery, {
    variables: {
      code: props.match.params.voucherId ? props.match.params.voucherId : "",
    },
  });

  useEffect(() => {
    if (data && data.checkVoucher.success) {
      setQuota(data.checkVoucher.quota);
    }
  }, [data]);

  const handleSubmit = async (
    values: initialValues,
    actions: FormikActions<initialValues>
  ) => {
    const { data } = await voucherVolume({
      variables: {
        input: {
          code: values.voucherId,
        },
      },
    });

    if (data && data.voucherVolume && data.voucherVolume.success) {
      setSubmitted(true);
    } else {
      setSubmitted(false);
      enqueueSnackbar(data?.voucherVolume?.errors.message, {
        variant: "error",
      });
    }
  };

  const initialValues = {
    voucherId: props.match.params.voucherId ? props.match.params.voucherId : "",
    volume: quota,
  };

  const validationSchema = Yup.object().shape({
    voucherId: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema,
  });

  return (
    <>
      {isSubmitted ? (
        <Success />
      ) : (
        <div className={classes.page}>
          <Paper className={classes.paper}>
            <>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => {
                  return (
                    <form onSubmit={formik.handleSubmit}>
                      <div className={classes.form}>
                        <TextField
                          fullWidth
                          variant="filled"
                          margin="normal"
                          label={t("Voucher Id")}
                          type="string"
                          name="voucherId"
                          value={formik.values.voucherId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.voucherId &&
                            Boolean(formik.errors.voucherId)
                          }
                          helperText={
                            formik.touched.voucherId && formik.errors.voucherId
                          }
                          disabled={props.match.params.voucherId ? true : false}
                        />
                        {props.match.params.voucherId && (
                          <TextField
                            fullWidth
                            variant="filled"
                            margin="normal"
                            label={t("Volume")}
                            type="string"
                            name="volume"
                            value={formik.values.volume}
                            onChange={formik.handleChange}
                            disabled
                          />
                        )}
                      </div>

                      <div className={classes.buttons}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {t("Redeem Voucher")}
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </>
          </Paper>
        </div>
      )}
    </>
  );
};
