import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Typography, Grid, Paper } from "@mui/material";

import Wizard from "../components/Wizard";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: { height: "100%" },
    page: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
      marginLeft: "auto",
      marginRight: "auto",
      minHeight: "35vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
    link: {
      textDecorationColor: theme.palette.primary.main,
      cursor: "pointer",
    },
    body: { padding: theme.spacing(1) },
  });
});

const Success = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CheckCircleIcon
        className={classes.icon}
        color="primary"
        fontSize="medium"
      />
      <Grid item>
        <Typography variant="h5" gutterBottom align="center">
          {t("Thank you for registration! ")}
        </Typography>
        <Typography variant="h5" gutterBottom align="center">
          {t(
            "We have sent a confirmation email. Please click the link in the email to get to your account."
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const RegistrationPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isSubmitted, setSubmitted] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      {isSubmitted ? (
        <Success />
      ) : (
        <div className={classes.page}>
          <Paper className={classes.paper}>
            <Wizard setSubmitted={setSubmitted} />
          </Paper>
          <Typography
            variant="body1"
            color="textSecondary"
            align="left"
            className={classes.body}
          >
            {t("Already Registered")}
            {"? "}
            <Link to="/login" className={classes.link}>
              <Typography variant="body1" color="primary" component="span">
                {t("Login")}
              </Typography>
            </Link>
          </Typography>
        </div>
      )}
    </>
  );
};
