import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// Components
import { Counter } from "../components/Counter";
import { formatValue } from "../../../Utils/markingProduct";
import { ProductPreview } from "../components/ProductPreview";
import { ProductName } from "../components/ProductName";
import { ProductDescription } from "../components/ProductDescription";
import { Label } from "../../../Components/Product/ProductLabel";

// Query
//Utils
import {
  getPriceByQuantity,
  getMinQuantity,
} from "../../../Utils/markingProduct";
import { getFragmentData } from "../../../gql";

import {
  MarkableFragment,
  MarkingProductFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType,
} from "../../../gql/graphql";
import { pricingRulesFragment } from "../pages/ProductList";
import { useFlag } from "../../../orderingFlags";

import {
  isAdditionalInfoComplete,
  useAdditionalInfo,
  AdditionalInfoInput,
} from "../../../brand/AdditionalInfo/";
import {
  isDuplicate,
  useShoppingCart,
} from "../../ShoppingCart/useShoppingCart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specification: {
      fontSize: "1.125em",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
  })
);
interface specificationProps {
  isDisplay: string;
  key: string;
  label: string;
  value: string;
}

export const Content = ({
  markingProduct,
  markable,
}: {
  markingProduct: MarkingProductFragment;
  markable: MarkableFragment;
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();
  const hidePrices = useFlag(["hidePrices"]);

  const [quantity, setQuantity] = useState<number>(
    markingProduct.quantityIncrement > 0 ? 0 : 1
  );

  const { values: additionalInfo, resetValues: resetAdditionalInfo } =
    useAdditionalInfo();

  const { cartItems, addCartItem } = useShoppingCart();

  const pricingRulesItems = getFragmentData(
    pricingRulesFragment,
    markingProduct.pricingRules
  );

  const specification = JSON.parse(markingProduct.specification);
  const minQuantity = getMinQuantity(pricingRulesItems);

  const updateQuantity = (props: { target: { value: string } }) => {
    setQuantity(
      isNaN(parseInt(props.target.value))
        ? parseInt("")
        : parseInt(props.target.value)
    );
  };

  const add = async () => {
    // FIXME where does this need to go!?!
    const addInfoParams = additionalInfo
      ? JSON.stringify(
          Object.fromEntries(
            additionalInfo.reduce((params, info) => {
              params.set(info.key, info.value);
              return params;
            }, new Map<string, string>())
          )
        )
      : undefined;

    // In case of total, quantityIncrement is set to zero
    // To make sure that the same marking product is not added again.
    if (
      markingProduct.quantityIncrement === 0 &&
      isDuplicate(
        { markable, markingProduct, quantity, additionalInfo: addInfoParams },
        cartItems
      )
    ) {
      enqueueSnackbar(
        t("Same product already been added to your shopping cart"),
        {
          variant: "warning",
        }
      );
    } else {
      addCartItem({
        markable,
        markingProduct,
        quantity,
        additionalInfo: addInfoParams,
      });
      enqueueSnackbar(t("Successfully added to your shopping cart"), {
        variant: "success",
      });
      setQuantity(markingProduct.quantityIncrement > 0 ? 0 : 1);
      resetAdditionalInfo();
    }
  };

  const price = formatValue(
    "currency",
    getPriceByQuantity(
      pricingRulesItems,
      quantity,
      markingProduct.labelsPerUnit
    ).price
  );

  const disabled =
    markingProduct.markingProductType === ProductType.Physical
      ? quantity < minQuantity || isNaN(quantity)
      : quantity < minQuantity ||
        isNaN(quantity) ||
        !isAdditionalInfoComplete(additionalInfo);

  return (
    <div
      style={{
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <ProductPreview markingProduct={markingProduct} />
        </Grid>

        <Grid item xs={12} md={4}>
          <ProductName
            productName={
              markingProduct.name || t("No Name defined for this Product")
            }
          ></ProductName>

          <Typography component="h5" variant="h5" align="left">
            {markingProduct.nart}
          </Typography>

          {!hidePrices && (
            <Typography component="div">
              <Typography display="inline" component="h3" variant="h3">
                {`${price}`}
              </Typography>
              <Typography display="inline" variant="h5">
                <Label
                  productType={markingProduct.markingProductType}
                  scaleBasis={pricingRulesItems[0].scaleBasis}
                />
              </Typography>
            </Typography>
          )}

          <Typography variant="subtitle1" color="textSecondary" align="left">
            {markingProduct.markingProductType !== ProductType.Digital &&
              `(${formatValue("number", markingProduct.labelsPerUnit)} ${t(
                "Labels per Roll"
              )})`}
          </Typography>

          {markingProduct.markingProductType === ProductType.Digital && (
            <ProductDescription markingProduct={markingProduct} />
          )}

          <Typography variant="subtitle1" color="textSecondary" align="left">
            {markingProduct.markingProductType !== ProductType.Digital &&
              `${t("Size")}: ${markingProduct.labelWidth} x ${
                markingProduct.labelLength
              }
              ${t("Size")}: ${markingProduct.labelWidth} x ${
                markingProduct.labelLength
              } mm`}
          </Typography>

          <br></br>
          <Grid item xs={12} style={{ padding: "16px" }}>
            <Counter
              minOrderQuantity={markingProduct.minOrderQuantity}
              quantityIncrement={markingProduct.quantityIncrement}
              markingProductType={markingProduct.markingProductType}
              value={quantity}
              labelsPerUnit={markingProduct.labelsPerUnit}
              onChange={updateQuantity}
            />
            {quantity > 1 &&
              quantity < markingProduct.minOrderQuantity &&
              markingProduct.markingProductType === ProductType.Digital && (
                <Typography color="error">
                  {t("Please enter minimum of {{codes}} codes", {
                    codes: new Intl.NumberFormat(i18n.language).format(
                      markingProduct.minOrderQuantity
                    ),
                  })}
                </Typography>
              )}
          </Grid>
          <AdditionalInfoInput markingProduct={markingProduct} />

          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={add}
            >
              {t("Add")}
              <ShoppingCartIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ul className={classes.specification}>
              {markingProduct.markingProductType === ProductType.Digital
                ? specification
                    .filter(
                      (item: specificationProps) => item.isDisplay === "true"
                    )
                    .map((item: specificationProps) => {
                      return (
                        <li key={item.key}>{`${item.label}: ${item.value}`}</li>
                      );
                    })
                    .filter(
                      (item: specificationProps) => item.isDisplay === "true"
                    )
                    .map((item: specificationProps) => {
                      return (
                        <li key={item.key}>{`${item.label}: ${item.value}`}</li>
                      );
                    })
                : Object.keys(specification).map((key, index) => {
                    return (
                      <li key={index}>{`${key}: ${specification[key]}`}</li>
                    );
                  })}
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
