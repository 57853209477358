import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../Components/PageContainer";
// Pages
import { VerifyEmailPage } from "./pages/VerifyEmailPage";

const VerifyEmailContainer = () => {
  return (
    <Switch>
      <Route
        path={"/register_verify/:id/:token"}
        render={(props) => <VerifyEmailPage {...props} />}
      />
    </Switch>
  );
};

interface VerifyEmailModule {
  (props: Object): JSX.Element;
}

export const VerifyEmail: VerifyEmailModule = (props) => {
  const { t } = useTranslation();
  return (
    <PageContainer centerTitle title={t("Confirm Email")} subTitle={""}>
      <VerifyEmailContainer />
    </PageContainer>
  );
};
