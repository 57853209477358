import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const locales: any = {
  en: "94c5949fbcf478b16a5ed2dd241dc227",
  //fr: "f83a91076e2c3dfa70a8d6cc86fb9db8",
};

const options = {
  loadPath: (lngs: string[], namespace: string[]): string => {
    return `https://api.phraseapp.com/api/v2/projects/d2a3c7c5b3ea2b59c89ba642604e8670/locales/${
      locales[lngs[0]]
    }/download?file_format=simple_json&tags=${namespace[0]}`;
  },

  customHeaders: {
    "Cache-Control": "no-store",
    Authorization:
      "token 823bbaf5b167d37dd79cabdfe7b5a81746d4562dc9cc1ec851268c7a1d378629",
    "Content-Type": "application/json"
  }
};

declare global {
  interface InitOptionsExtended extends i18next.InitOptions {
    supportedLanguages: string[];
  }
}

i18next
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    defaultNS: "frontend",
    ns: ["frontend"],
    backend: options,
    // Development only
    // supportedLanguages: ["en", "yo"],
    // whitelist: ["en", "yo"],
    supportedLanguages: ["en"],
    whitelist: ["en"],
    keySeparator: ":::",
    nsSeparator: "::"
  } as InitOptionsExtended);
