export const palette: any = {
  material: {
    type: "light",
    primary: {
      main: "#009DDF",
    },
    secondary: {
      main: "#7C7C7C",
    },
    background: {
      paper: "#FFFFFF",
      default: "#fafafa",
    },
  },
  generic: [
    "#66A5BE",
    "#1E4B5C",
    "#0084B7",
    "#78939D",
    "#706F6F",
    "#336E7B",
    "#A1B9B8",
    "#bfbfbf",
    "#FFAB00",
  ],
};
