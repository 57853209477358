import React from "react";
import AppContainer from "../AppContainer";
import { FlagsProvider } from "../orderingFlags";

const FeaturesContainer = () => {
  return (
    <FlagsProvider
      flags={{
        allowedRegister: true,
        minimumOrder: true,
        volumeControl: { enabled: true },
        allowedCountries: [
          { code: "FR", name: "France" },
          { code: "DE", name: "Germany" },
        ],
        footers: {
          imprint: {
            markdown: false,
            links: {
              en: "https://www.scribos.com/imprint-conditions-of-use",
            },
          },
          privacypolicy: {
            markdown: false,
            links: {
              en: "https://www.scribos.com/privacy-policy",
            },
          },
          contact: {
            markdown: false,
            links: {
              en: "https://www.scribos.com/contact",
            },
          },
        },
        companyProfile: {
          userPanel: false,
          nonEditableAddressCategories: [],
        },
      }}
    >
      <AppContainer />
    </FlagsProvider>
  );
};

export default FeaturesContainer;
