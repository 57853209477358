import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//Queries
import { useQuery } from "@apollo/client";

// Components
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { graphql } from "../../../gql";

interface Values {
  newpassword: string;
  confirmpassword: string;
}

interface Props {
  match: {
    params: {
      id: string;
      token: string;
    };
  };
}

export const ActivateUserQuery = graphql(/* GraphQL */ `
  query activateUser($activationCheck: UserActivationInput!) {
    activateUser(activationCheck: $activationCheck) {
      success
      errors
    }
  }
`);

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      padding: "1em",
      display: "flex",
      flexDirection: "column",
      minWidth: "400px",
      minHeight: "300px",
      justifyContent: "center",
      alignItems: "center",
    },
    page: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
      marginLeft: "auto",
      marginRight: "auto",
      minHeight: "35vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
  });
});

const Success = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CheckCircleIcon
        className={classes.icon}
        color="primary"
        fontSize="medium"
      />
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {t(
            "Thank you for confirming your email. You can go ahead and login."
          )}
        </Typography>
      </Grid>

      <Grid
        item
        justifyContent="center"
        alignItems="center"
        container
        spacing={2}
      >
        <Grid item>
          <Link
            to={"/login"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="contained" color="primary">
              {t("Login now")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

const InvalidToken = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {t(
            "The link was invalid, possibly because it has already been used. Please request a new confirmation link."
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const VerifyEmailPage = (props: Props) => {
  const id = props.match.params.id;
  const token = props.match.params.token;

  const { data, loading } = useQuery(ActivateUserQuery, {
    variables: {
      activationCheck: {
        uidb64: id,
        token: token,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  return data && data.activateUser && data.activateUser.success ? (
    <Success />
  ) : (
    <InvalidToken />
  );
};
