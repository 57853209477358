import React, { Fragment } from "react";

import RedeemIcon from "@mui/icons-material/Redeem";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { Redeem } from "./Redeem";

type Props = {
  sortOrder: number;
};

export const RedeemModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"redeem"}
        displayName={() => t("Redeem Voucher")}
        path={"/redeem"}
        routeParams={":voucherId?"}
        showInMenu={false}
        icon={() => <RedeemIcon />}
        loginRequired={true}
        hideWhenLoggedIn={true}
        component={() => <Redeem />}
      />
      <ModuleRoute
        sortOrder={sortOrder}
        name={"redeem"}
        displayName={() => t("Redeem Voucher")}
        path={"/redeem"}
        // routeParams={":voucherId?"}
        showInMenu={true}
        icon={() => <RedeemIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <Redeem />}
      />
    </Fragment>
  );
};
