import React, { Fragment } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ModuleRoute } from "../../Components/ModuleRoute";
import { useTranslation } from "react-i18next";
import { Registration } from "./Registration";
import { VerifyEmail } from "./VerifyEmail";

type Props = {
  sortOrder: number;
};

export const RegistrationModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"register"}
        displayName={() => t("Register")}
        path={"/register"}
        //routeParams={":voucherId?"}
        icon={() => <AccountCircleIcon />}
        loginRequired={false}
        hideWhenLoggedIn={true}
        component={() => <Registration />}
      />

      <ModuleRoute
        sortOrder={sortOrder}
        name={"confirm email"}
        displayName={() => t("Confirm email")}
        path={"/register_verify/:id/:token"}
        //routeParams={"/:id?/:token?"}
        showInMenu={false}
        icon={() => <AccountCircleIcon />}
        loginRequired={false}
        hideWhenLoggedIn={true}
        component={() => <VerifyEmail />}
      />
    </Fragment>
  );
};
