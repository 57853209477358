import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Button, TextField } from "@mui/material";
//Query
import { useMutation, useLazyQuery } from "@apollo/client";

import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    slide: { minHeight: "30vh" },
    buttons: { padding: theme.spacing(2) },
    link: { textDecorationColor: theme.palette.primary.main },
    page: {
      padding: theme.spacing(2),
    },
  });
});

interface initialValues {
  email: string;
  password: string;
  repeatPassword: string;
}

export const CheckEmailQuery = graphql(/* GraphQL */ `
  query checkemailexistence($email: String!) {
    emailExistsForHp(email: $email)
  }
`);

export const VoucherRegisterMutation = graphql(/* GraphQL */ `
  mutation voucherRegister($input: VoucherRegisterMutationInput!) {
    voucherRegister(input: $input) {
      success
      errors
    }
  }
`);

export const RequestRegistrationVoucherMutation = graphql(/* GraphQL */ `
  mutation requestRegistrationVoucher(
    $input: RequestRegistrationVoucherMutationInput!
  ) {
    requestRegistrationVoucher(input: $input) {
      code
      errors
    }
  }
`);

const Wizard: React.FC<{
  setSubmitted: (isSubmitted: boolean) => void;
}> = ({ setSubmitted }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();

  const [checkEmail, { data: checkEmailData }] = useLazyQuery(CheckEmailQuery);

  const [register, { error }] = useMutation(VoucherRegisterMutation);

  const [requestVoucher] = useMutation(RequestRegistrationVoucherMutation);

  if (error) throw error;

  const handleSubmit = async (
    values: initialValues,
    actions: FormikActions<initialValues>
  ) => {
    // fetch the voucherId
    const { data } = await requestVoucher({
      variables: {
        input: {},
      },
    });

    if (data && data!.requestRegistrationVoucher!.code) {
      // register the user with voucherId
      const { data: registerData } = await register({
        variables: {
          input: {
            email: values.email,
            password: values.password,
            code: data!.requestRegistrationVoucher!.code,
          },
        },
      });

      if (
        registerData &&
        registerData.voucherRegister &&
        registerData.voucherRegister.success
      ) {
        setSubmitted(true);
      } else {
        setSubmitted(false);
        enqueueSnackbar(registerData?.voucherRegister?.errors.message, {
          variant: "error",
        });
      }
    }
  };

  const initialValues = {
    email: "",
    password: "",
    repeatPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Enter a valid email"))
      .required(t("Required"))
      .test(
        "unique-email",
        "This email is already taken, please specify another one",
        async (email) => {
          await checkEmail({ variables: { email: email } });
          if (!checkEmailData?.emailExistsForHp) {
            return true;
          }

          return false;
        }
      ),
    password: Yup.string()
      .required(t("Required"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("Password does not match"))
      .required(t("Required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <div className={classes.page}>
                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  label={t("Email")}
                  type="string"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  label={t("Password")}
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  label={t("Repeat Password")}
                  type="password"
                  name="repeatPassword"
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.repeatPassword &&
                    Boolean(formik.errors.repeatPassword)
                  }
                  helperText={
                    formik.touched.repeatPassword &&
                    formik.errors.repeatPassword
                  }
                />
              </div>

              <div className={classes.buttons}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t("Register")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
export default Wizard;
