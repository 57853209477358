import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../Components/PageContainer";

// Pages
import { RegistrationPage } from "./pages/RegistrationPage";
import { VerifyEmail } from "./VerifyEmail";

const RegisterationContainer = () => {
  return (
    <Switch>
      <Route exact path={"/register"} render={() => <RegistrationPage />} />
      <Route
        path={"/register_verify/:id/:token"}
        render={(props) => <VerifyEmail {...props} />}
      />
    </Switch>
  );
};

interface RegistrationModule {
  (props: Object): JSX.Element;
}

export const Registration: RegistrationModule = (props) => {
  const { t } = useTranslation();
  return (
    <PageContainer
      centerTitle
      title={t("Registration")}
      subTitle={t("Please register for Ordering")}
    >
      <RegisterationContainer />
    </PageContainer>
  );
};
