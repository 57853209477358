import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../Components/PageContainer";

// Pages
import { RedeemPage } from "./RedeemPage";

const RedeemContainer = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/redeem/:voucherId"}
        render={(props) => <RedeemPage {...props} />}
      />

      <Route
        exact
        path={"/redeem"}
        render={(props) => <RedeemPage {...props} />}
      />
    </Switch>
  );
};

interface RedeemModule {
  (props: Object): JSX.Element;
}

export const Redeem: RedeemModule = () => {
  const { t } = useTranslation();
  const header = [{ name: t("Redeem Voucher"), urlRegex: /^\/redeem/ }];

  return (
    <PageContainer
      title={t("Redeem Voucher")}
      subTitle={""}
      header={{
        breadcrumbs: header,
      }}
    >
      <RedeemContainer />
    </PageContainer>
  );
};
